// Colors
$white_color: #fff;
$grey_super_light_color: #eee;
$grey_super_light_color_2: #ddd;
$grey_light_color: #bbb;
$grey_color: #888;
$grey_mid_color: #444;
$grey_hard_color: #222;
$black: #000;
$redcolor: #ad0000;
$redLightcolor: #ff0000;
$body_color: #445555;
// $body_background-color: #04324e;
$body_background-color: #9f9f9f;
$deep_color: #01426e;
$light_color: #b6f6ff;
$middle_color: #216672;
$link_color: #9ed1ee;
$button_color: #196f4d;

$color_deep_color_0: rgba($deep_color, 1);
$color_deep_color_01: rgba($black, 1);
$color_deep_color_1: rgba($black, 0.8);
$color_deep_color_2: rgba($deep_color, 0.85);
$color_deep_color_3: rgba($deep_color, 0.45);

$color_light_color_0: rgba($light_color, 1);
$color_light_color_025: rgba($light_color, 0.25);
$color_light_color_4: rgba($light_color, 0.4);
$color_light_color_7: rgba($light_color, 0.7);

$black_transparent_1: rgba($black, 0.85);
$black_transparent_2: rgba($black, 0.45);
$black_transparent_3: rgba($black, 0.75);

// Shadows
$box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.5);

body {
  color: $body_color;
  background-color: $body_background-color;
}
a {
  color: $link_color;
  &:hover {
    color: $link_color;
  }
}
h1,
h2,
h3 {
  font-family: "Open Sans Condensed", "Arial Narrow", sans-serif;
}
h1 {
  font-size: 54px;

  font-weight: 300;
  padding: 30px 0;
  margin: 0;
  line-height: 100%;
  color: $white_color;
  // color:rgba($white_color, 0.85);
}
.clear {
  clear: both;
}
.btn-link {
  color: $color_light_color_7;
  &:hover {
    color: $color_light_color_7;
  }
}
.card {
  width: 100%;
}
.badge-danger {
  background-color: $redLightcolor;
}

// Breadcrumb
.breadcrumb {
  background-color: transparent;
  border-bottom: 0;
  padding: 5px 0 5px 30px;
  margin-bottom: 0;
}
.breadcrumb-item {
  a {
    color: $color_deep_color_2;
  }
  &.active {
    color: $white_color;
    &:before {
      color: $white_color;
    }
  }
}
.app-footer {
  padding: 0 30px;
  background: $color_deep_color_1;
  color: rgba($white_color, 0.7);
  border-top: 0;
}
.navbar-brand-full {
  margin-left: -10px;
}

.arrow-visible,
.arrow-decrease {
  display: block;
  font-size: 1.5em;
  line-height: 20px;
  width: 7px;
}
.arrow-visible {
  -moz-transform: scaleX(2) rotate(-90deg);
  -ms-transform: scaleX(2) rotate(-90deg);
  -webkit-transform: scaleX(2) rotate(-90deg);
  -o-transform: scaleX(2) rotate(-90deg);
  transform: scaleX(2) rotate(-90deg);
  margin: 0 55% 0 45%;
}
.arrow-decrease {
  -moz-transform: scaleX(2) rotate(90deg);
  -ms-transform: scaleX(2) rotate(90deg);
  -webkit-transform: scaleX(2) rotate(90deg);
  -o-transform: scaleX(2) rotate(90deg);
  transform: scaleX(2) rotate(90deg);
  margin: 0 48% 0 52%;
}

// Map View
.auction-logo-wrapper {
  img {
    height: 60px;
  }
}
.map-basics-wrapper {
  position: relative;
  margin-bottom: 1.5rem;
}
.map-ships-control {
  position: absolute;
  top: 30px;
  right: 20px;
  background: $black_transparent_3;
  color: $color_light_color_0;
  padding: 0 0 7px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 5px 5px 10px 2px ($grey_color);
  & > div {
    border-bottom: 1px dotted;
    margin: 0 15px 5px;
  }
  div {
    display: table;
    &:hover {
      cursor: pointer;
    }
  }
  &.decrease {
    height: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .visible,
  .decrease {
    width: 100%;
    border-bottom: 1px solid;
    margin: 0 0 5px 0;
    padding: 0;
  }
}
.arrow-visible,
.arrow-decrease {
  display: block;
  font-size: 1.5em;
  line-height: 20px;
  width: 7px;
}
.arrow-visible {
  -moz-transform: scaleX(2) rotate(-90deg);
  -ms-transform: scaleX(2) rotate(-90deg);
  -webkit-transform: scaleX(2) rotate(-90deg);
  -o-transform: scaleX(2) rotate(-90deg);
  transform: scaleX(2) rotate(-90deg);
  margin: 0 55% 0 45%;
}
.arrow-decrease {
  -moz-transform: scaleX(2) rotate(90deg);
  -ms-transform: scaleX(2) rotate(90deg);
  -webkit-transform: scaleX(2) rotate(90deg);
  -o-transform: scaleX(2) rotate(90deg);
  transform: scaleX(2) rotate(90deg);
  margin: 0 48% 0 52%;
}

// Carouseles Remake Initial Styles
.card {
  border: 0;
  border-radius: 0;
  margin-top: 1.5rem;
}
.card-body {
  padding: 0;
}
.carousel-control-prev,
.carousel-control-next {
  width: 40px;
}
.carousel-indicators {
  bottom: -35px;
}
// Carouseles Styles

.lot-block-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border: 1px solid $grey_color;
  border-right: 0;
}
.lot-item-wrapper {
  position: relative;
  width: 100%;
  background: rgba($grey_color, 0.5);
  padding: 5px;
  cursor: pointer;
  border-right: 1px solid $grey_color;
  &:hover {
    background: rgba($grey_color, 1);
    img {
      opacity: 1;
      -webkit-box-shadow: $box-shadow;
      -moz-box-shadow: $box-shadow;
      box-shadow: $box-shadow;
    }
  }
}
.lot-img-wrapper {
  height: 90%;
  background: rgba($grey_color, 0.6);
  padding: 5px;
}
.lot-text-item-wrapper {
  height: 10%;
}
.lot-date {
  float: left;
  font-weight: 700;
}
.lot-price {
  float: right;
  color: $redcolor;
  font-weight: 700;
}
.lot-image {
  display: flex;
  vertical-align: middle;
  margin: 0 auto;
  max-width: 100%;
  // max-height: 100%;
}
.antiquaroom-logo {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 100px;
  opacity: 0.75;
}

// Catalog View
.catalog-auction-logo {
  height: 20px;
}
.catalog-accept {
  position: absolute;
  right: 10px;
  cursor: pointer;
  opacity: 0.5;
  &:hover{
    opacity: 1;


text-shadow: 1px 0px 1px rgba($grey_color, 1), 0px 1px 1px rgba($grey_color, 0.1), 2px 1px 1px rgba($grey_color, 1), 1px 2px 1px rgba($grey_color, 0.1), 3px 2px 1px rgba($grey_color, 1), 2px 3px 1px rgba($grey_color, 0.1), 4px 3px 1px rgba($grey_color, 1), 3px 4px 1px rgba($grey_color, 0.1), 5px 4px 1px rgba($grey_color, 1), 4px 5px 1px rgba($grey_color, 0.1), 6px 5px 1px rgba($grey_color, 1), 5px 6px 1px rgba($grey_color, 0.1), 7px 6px 1px rgba($grey_color, 1);


  }
}
.catalog-block-wrapper {
  .card {
    border: 1px solid $grey_mid_color;
    margin-top: 0;
  }
  .card-header {
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    background: $grey_color;
    border-radius: 0;
    color: $white_color;
    border-bottom: 1px solid $grey_mid_color;
  }
  .catalog-auction-logo {
    // height: 20px;
    margin-right: 5px;
  }
  .catalog-auction-number {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    display: table;
    margin: 10px auto 0;
    padding: 0 15px 5px;
    border-bottom: 1px solid $grey_light_color;
  }
  .catalog-text {
    padding: 10px 15px;
    height: 75px;
    overflow: hidden;
    line-height: 1.2;
  }
  .catalog-image {
    padding: 5px;
    background: $grey_light_color;
    border-bottom: 1px solid $grey_mid_color;
    img {
      
    }
  }
  .card-footer {
    border-top: 1px solid $grey_mid_color;
  }
  li.disabled.page-item {
    opacity: 0.5;
    display: none;
  }
}
.catalog-button {
  float: right;
}

// List View
.list-wrapper {
  .container {
    width: 100%;
    max-width: 100%;
    padding: 0;
    .row {
      margin: 0;
    }
  }
  table {
    background: $grey_super_light_color;
    margin-bottom: 1.5rem;
    tr {
      &:nth-child(even) td {
        background: $grey_super_light_color_2;
      }
    }
    th {
      background: $grey_light_color;
      border: 1px solid $grey_mid_color;
      border-bottom: 0;
      &:first-child {
        width: 30px;
      }
    }
    td {
      border: 1px solid $grey_mid_color;
      padding: 5px;
    }
    a {
      color: $redcolor;
      &.btn {
        color: $white_color;
      }
    }
  }
}

// React Big Calendar
.rbc-calendar {
  background: #fff;
  height: 600px !important;
  margin-bottom: 1.5rem;
}

// FullCalendar
.demo-app {
  background: #fff;
  margin-bottom: 1.5rem;
  padding: 15px;
}
.fc-content {
  color: $white_color;
  padding: 3px;
}
//PopUp FullCalendar Window
#info_wrapper_wrapper {
  display: none;
  &.active_info {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100000;
    background: $black_transparent_1;
  }
}
#info_wrapper_outer {
  width: 70%;
  margin: 2% auto;
  background: $white_color;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}
#info_wrapper_inner {
  padding: 15px;
  background: $white_color;
}
#info_header_inner {
  padding: 15px;
  // border-bottom: 1px solid $grey_color;
  border-bottom: 1px solid $grey_hard_color;
  background: $grey_color;
  color: $white_color;
  text-transform: uppercase;
  font-size: 1.5rem;
}
#info_moreinfo_inner {
  padding: 15px;
  background: $white_color;
  // border-top: 1px solid $grey_color;
  border-top: 1px solid $grey_hard_color;
  background: $grey_color;
  font-style: italic;
  text-align: right;
  a {
    color: $white_color;
  }
}
.close_button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
  &:after {
    content: "";
    background: url(../assets/img/close.svg) no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    color: $grey_color;
  }
  &:hover {
    -webkit-box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
  }
}
.description_img {
  float: left;
  margin: 0 15px 0 0;
}

// Top Search
.title-option {
  font-weight: 700;
  color: $grey_light_color;
}
.top-search-wrapper {
  width: 65%;
}
.top-search-block {
  padding: 0 0 0 30px;
  width: 100%;
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    border-radius: 0;
    border: 0;
    height: 30px;
  }
  .form-control,
  .btn {
    padding: 3px 5px 4px;
  }
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    padding-right: 3px;
    padding-left: 0;
  }
}

// Left Search
.search-filter-item-wrapper {
  .btn-link {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .nav {
    border-bottom: 1px solid $grey_color;
  }
  .btn {
    display: block;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
  }
  button[aria-expanded="true"] {
    margin-bottom: 15px !important;
    background: $black_transparent_2;
  }
  button[aria-expanded="false"] {
    border-bottom: 1px solid $grey_color;
  }
  button[aria-expanded="true"] .nav-dropdown-toggle::before {
    transform: rotate(-90deg);
  }
  .nav-item {
    padding: 0 1rem;
    line-height: 1;
    margin-bottom: 15px;
  }
  .form-check-input {
    margin-top: 0;
  }
}

//Styles
.app-header {
  background-color: $color_deep_color_1;
  .dropdown-item {
    min-width: auto;
  }
  .nav-item {
    min-width: 50px;
  }
  .navbar-toggler {
    .navbar-toggler-icon {
      background-image: none;
      &:hover {
        background-image: none;
      }
      &:before {
        font-family: simple-line-icons;
        content: "\E601";
        font-size: 1.2em;
        color: $color_light_color_4;
      }
      &:hover:before {
        color: $color_light_color_0;
      }
    }
    &:hover {
      .navbar-toggler-icon {
        background-image: none;
      }
    }
  }
  .dropdown {
    margin-right: 10px;
    .px-3 {
      &.nav-link {
        padding-right: 0 !important;
      }
    }
  }
  .lang-wrapper {
    margin-right: 0;
  }
  .header-dropdown {
    background-color: $color_deep_color_1;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $color_light_color_4;
    .dropdown-item {
      color: $color_light_color_7;
      border-bottom-color: $color_light_color_4;
    }
    .dropdown-item:hover {
      background-color: inherit;
      color: $color_light_color_0;
    }
  }
  .navbar-nav {
    margin-left: 10px;
    .nav-link {
      &.active {
        background: $color_light_color_7;
        border-radius: 5px;
        color: $black;
      }
    }
    .nav-link,
    a {
      color: $color_light_color_7;
      cursor: pointer;
      &:hover {
        color: $color_light_color_0;
      }
      &:focus {
        color: $color_light_color_0;
        i {
          // color: $black;
          .dropdown-toggle-icon {
            color: $color_light_color_0;
          }
        }
      }
    }
  }
}

.navbar-toggler:focus {
  //   border-color: $color_light_color_7;
  //   outline-color: $color_light_color_4;
  border-color: transparent;
  outline-color: transparent;
}
.dropdown-toggle-icon {
  position: absolute;
  margin-top: 6px;

  position: absolute;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;

  right: -8px;
  &:before {
    display: block;
    padding: 0;
    content: "\f105";
    font: normal normal normal 10px/1 FontAwesome;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: -webkit-transform 0.3s;
    transition: -webkit-transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
  }
}
.show {
  .dropdown-toggle-icon {
    -webkit-transform-origin: center 6px;
    -moz-transform-origin: center 6px;
    -o-transform-origin: center 6px;
    -ms-transform-origin: center 6px;
    transform-origin: center 6px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}
.site-phone {
  line-height: 18px;
  font-size: 1.5em;
  span {
    color: $color_light_color_0;
    margin-left: 7px;
  }
}
.nav-dropdown-items {
  .nav-link {
    padding-left: 2.5rem;
  }
}
.nav-dropdown-items,
.sidebar {
  background: $color_deep_color_1;
  .nav-dropdown {
    &.open {
      .nav-icon {
        color: $color_light_color_7;
      }
      .nav-link {
        background-color: rgba($light_color, 0.15);
        color: $color_light_color_7;
        &:hover {
          color: $color_light_color_0;
        }
      }
    }
    .nav-link {
      &.active {
        background-color: rgba($light_color, 0.25);
        color: $color_light_color_0;
      }
      &:hover {
        background-color: $color_light_color_4;
        color: $color_light_color_0;
      }
    }
  }
  .nav-link {
    color: $color_light_color_7;
    &.active {
      background-color: $color_light_color_025;
      color: $color_light_color_7;
      .nav-icon {
        color: $color_light_color_7;
      }
    }
    &:hover {
      background-color: $color_light_color_4;
      color: $color_light_color_0;
      .nav-icon {
        color: $color_light_color_0;
      }
    }
  }
  .ps__rail-y {
    width: 7px;
  }
  .ps__thumb-y {
    background-color: $color_light_color_7;
    width: 3px;
  }
  .ps__rail-y:hover > .ps__thumb-y,
  .ps__rail-y:focus > .ps__thumb-y,
  .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: $color_light_color_7;
    width: 3px;
  }
  .ps .ps__rail-x:hover,
  .ps .ps__rail-y:hover,
  .ps .ps__rail-x:focus,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-x.ps--clicking,
  .ps .ps__rail-y.ps--clicking {
    background-color: #eee;
    opacity: 0.9;
  }
}
.nav-dropdown-items .ps .ps__rail-x:hover,
.nav-dropdown-items .ps .ps__rail-y:hover,
.nav-dropdown-items .ps .ps__rail-x:focus,
.nav-dropdown-items .ps .ps__rail-y:focus,
.nav-dropdown-items .ps .ps__rail-x.ps--clicking,
.nav-dropdown-items .ps .ps__rail-y.ps--clicking,
.sidebar .ps .ps__rail-x:hover,
.sidebar .ps .ps__rail-y:hover,
.sidebar .ps .ps__rail-x:focus,
.sidebar .ps .ps__rail-y:focus,
.sidebar .ps .ps__rail-x.ps--clicking,
.sidebar .ps .ps__rail-y.ps--clicking {
  background: transparent;
}

.phone-email-block {
  margin-right: 30px;
}
h4.page-title {
  text-align: center;
  text-transform: uppercase;
  color: $middle_color;
}
.main-page-table {
  tr {
    &:hover td {
      background-color: $color_deep_color_3;
      color: white;
      a {
        color: white;
        text-decoration: underline;
      }
    }
  }
  th,
  td {
    vertical-align: middle !important;
  }
  td {
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }
  th,
  td.title-td {
    background-color: $color_deep_color_2;
    color: $color_light_color_0;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    &:hover {
      background-color: $color_deep_color_2;
      color: $color_light_color_0;
    }
  }
}
.marked-text {
  font-weight: bold;
  color: $redcolor;
}
.header-flags {
  height: 20px;
  i {
    height: 100%;
    width: 100%;
    line-height: inherit;
  }
}

@media (max-width: 1199.98px) {
  .tabs-with-main-icons .nav-item .main-icon {
    height: 70px;
  }
  .nav-link {
    font-size: 0.8em;
  }
}
@media (min-width: 992px) {
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
    background: $color_deep_color_0;
  }
  .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
    background-color: $color_deep_color_0;
    &:hover {
      background-color: rgba($middle_color, 1);
    }
  }
}
@media (max-width: 991.98px) {
  .site-phone {
    display: block !important;
  }
  .app-header .navbar-brand {
    position: absolute;
    left: 60px;
    width: auto;
    margin-left: 0;
  }
  .tabs-with-main-icons .nav-item .main-icon {
    height: 50px;
  }
}

@media (max-width: 767.98px) {
  #info_wrapper_outer {
    width: 95%;
  }
  .description_img {
    float: none;
    margin: 0 0 15px 0;
  }
  .main .container-fluid {
    padding: 0 5px;
  }
  .tabs-with-main-icons {
    margin-top: 5px;
  }
  .nav-tabs {
    .nav-link {
      padding: 0;
      span {
        display: none;
      }
    }
  }
  .tab-content .tab-pane {
    padding: 1rem 5px;
  }
  .main-page-table {
    th,
    td {
      padding: 5px;
    }
  }
  .app-footer {
    padding: 0 5px;
  }
  .info_block_row > div {
    display: block;
    width: 100%;
  }
}
@media (max-width: 599.98px) {
  h4.page-title {
    font-size: 0.8em;
  }
  .main-page-table {
    th,
    td {
      padding: 2px;
      font-size: 0.8em;
    }
  }
}

@media (max-width: 479.98px) {
  .tabs-with-main-icons .nav-item .main-icon {
    height: 40px;
  }
  .main-page-table {
    th,
    td {
      padding: 1px;
      font-size: 0.7em;
    }
  }
  .app-footer {
    font-size: 0.8em;
  }
  .navbar-brand-full {
    display: none;
  }
  .app-header .navbar-brand .navbar-brand-minimized {
    display: block;
  }
}
